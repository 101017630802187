<template>
  <div>
    <div class="m-2">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Afficher</label>
          <v-select
            v-model="perPage"
            :dir="'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            @input="fetchUsers(true)"
          />
          <label>entrées</label>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form @submit.prevent="fetchUsers(true)">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Rechercher..."
              />
              <b-button
                type="submit"
                variant="primary"
              >
                <span class="text-nowrap">Chercher</span>
              </b-button>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="users"
      responsive
      :fields="tableColumns"
      primary-key="uuid"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Aucun résultat trouvé"
      :sort-desc.sync="isSortDirDesc"
      :no-local-sorting="true"
      :busy="loading"
      @sort-changed="sortUsers($event)"
    >
      <!-- busy -->
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner label="Loading..." />
        </div>
      </template>
      <!-- Column: User -->
      <template #cell(name)="data">
        <b-media
          vertical-align="center"
          :class="{'cursor-not-allowed':!!data.item.deleted_at}"
        >
          <template #aside>
            <b-avatar
              :disabled="!!data.item.deleted_at"
              :class="{'cursor-not-allowed':!!data.item.deleted_at}"
              size="32"
              :src="data.item.avatar?data.item.avatar.image:null"
              :text="avatarText(data.item.name)"
              :variant="`light-${resolveUserRoleVariant(data.item)}`"
              :to="{ name: 'users-view', params: { id: data.item.uuid } }"
            />
          </template>
          <b-link
            :disabled="!!data.item.deleted_at"
            :to="{ name: 'users-view', params: { id: data.item.uuid } }"
            class="font-weight-bold d-block text-nowrap"
            :style="{cursor: data.item.deleted_at ? 'not-allowed': ''}"
          >
            {{ data.item.name }}
          </b-link>
          <small class="text-muted">@{{ data.item.email }}</small>
        </b-media>
      </template>

      <!-- Column: Plateforme -->
      <template #cell(platform)="data">
        <div
          v-if="data.item.platform"
          class="text-nowrap"
        >
          <b-link
            v-if="data.item"
            :to="{ name: 'platforms-view', params: { id: data.item.platform.uuid } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.platform.name }}
          </b-link>
        </div>
      </template>

      <!-- Column: Role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="resolveUserRoleIcon(data.item)"
            size="18"
            class="mr-50"
            :class="`text-${resolveUserRoleVariant(data.item)}`"
          />
          <span class="align-text-top text-capitalize">{{ resolveUserRoleName(data.item) }}</span>
        </div>
      </template>

      <!-- Column: notification -->
      <template #cell(notification)="data">
        <b-badge
          pill
          :variant="`light-${resolveUserBooleanVariant(data.item.notification)}`"
          class="text-capitalize"
        >
          {{ data.item.notification }}
        </b-badge>
      </template>

      <!-- Column: email_verified -->
      <template #cell(email_verified)="data">
        <b-badge
          pill
          :variant="`light-${resolveUserBooleanVariant(data.item.email_verified)}`"
          class="text-capitalize"
        >
          {{ data.item.email_verified }}
        </b-badge>
      </template>

      <!-- Column: created_at -->
      <template #cell(created_at)="data">
        <span>{{ moment(data.value).format("LLL") }}</span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <template v-if="!data.item.deleted_at">
            <feather-icon
              :id="`user-row-${data.item.uuid}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer"
              size="16"
              @click="$router.push({ name: 'users-view', params: { id: data.item.uuid }})"
            />
            <b-tooltip
              title="Détails"
              class="cursor-pointer"
              :target="`user-row-${data.item.uuid}-show-icon`"
            />

            <feather-icon
              :id="`user-row-${data.item.uuid}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mx-1"
              size="16"
              @click="$router.push({ name: 'users-edit', params: { id: data.item.uuid }})"
            />
            <b-tooltip
              title="Éditer"
              class="cursor-pointer"
              :target="`user-row-${data.item.uuid}-edit-icon`"
            />

            <feather-icon
              :id="`user-row-${data.item.uuid}-delete-icon`"
              v-b-modal="`modal-delete-user-${data.item.uuid}`"
              icon="TrashIcon"
              class="cursor-pointer"
              size="16"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`user-row-${data.item.uuid}-delete-icon`"
            />
            <b-modal
              :id="`modal-delete-user-${data.item.uuid}`"
              :ref="`modal-delete-user-${data.item.uuid}`"
              ok-variant="danger"
              ok-title="Supprimer"
              modal-class="modal-danger"
              centered
              title="Suppression de la plateforme"
            >
              <b-card-text>
                Souhaitez vous vraiment supprimer cet utilisateur ?<br>
                Ces éléments seront supprimés :<br>
                -Messages<br>
                -Pronostics (sur les matchs et les questions)<br>
                -Les points du joueur seront remis à 0 (ainsi que le classement)
                <p class="text-danger font-weight-bold">
                  (Cette action est réversible)
                </p>
              </b-card-text>
              <template #modal-footer>
                <b-button
                  variant="secondary"
                  @click="hideModal(`modal-delete-user-${data.item.uuid}`)"
                >
                  annuler
                </b-button>
                <b-button
                  variant="danger"
                  :disabled="actionLoading"
                  @click="deleteUser(data.item.uuid)"
                >
                  <b-spinner
                    v-show="actionLoading"
                    small
                  />
                  supprimer
                </b-button>
              </template>
            </b-modal>
          </template>
          <template v-if="data.item.deleted_at">
            <!-- restore -->
            <feather-icon
              :id="`user-row-${data.item.uuid}-restore-icon`"
              v-b-modal="`modal-restore-user-${data.item.uuid}`"
              icon="RotateCcwIcon"
              class="cursor-pointer mr-1"
              size="16"
            />
            <b-tooltip
              title="Restaurer"
              class="cursor-pointer"
              :target="`user-row-${data.item.uuid}-restore-icon`"
            />
            <b-modal
              :id="`modal-restore-user-${data.item.uuid}`"
              :ref="`modal-restore-user-${data.item.uuid}`"
              ok-variant="danger"
              ok-title="Restaurer"
              modal-class="modal-danger"
              centered
              title="Restauration de l'utilisateur'"
            >
              <b-card-text>
                Souhaitez vous vraiment restaurer cet utilisateur ?<br>
                Ces éléments seront réstaurés :<br>
                -Messages<br>
                -Pronostics (sur les matchs et les questions)<br>
                -Les points du joueur (Classement mis à jour)
              </b-card-text>
              <template #modal-footer>
                <b-button
                  variant="secondary"
                  @click="hideModal(`modal-restore-user-${data.item.uuid}`)"
                >
                  annuler
                </b-button>
                <b-button
                  variant="success"
                  :disabled="actionLoading"
                  @click="restoreUser(data.item.uuid)"
                >
                  <b-spinner
                    v-show="actionLoading"
                    small
                  />
                  restaurer
                </b-button>
              </template>
            </b-modal>
            <!-- force delete -->
            <feather-icon
              :id="`user-row-${data.item.uuid}-force-delete-icon`"
              v-b-modal="`modal-force-delete-user-${data.item.uuid}`"
              icon="TrashIcon"
              class="cursor-pointer"
              size="16"
            />
            <b-tooltip
              title="Supprimer définitivement"
              class="cursor-pointer"
              :target="`user-row-${data.item.uuid}-force-delete-icon`"
            />
            <b-modal
              :id="`modal-force-delete-user-${data.item.uuid}`"
              :ref="`modal-force-delete-user-${data.item.uuid}`"
              ok-variant="danger"
              ok-title="Supprimer"
              modal-class="modal-danger"
              centered
              title="Suppression définitive de l'utilisateur"
            >
              <b-card-text>
                Souhaitez vous vraiment supprimer définitivement cet utilisateur ?<br>
                Ces éléments seront supprimés définitivement :<br>
                -Messages<br>
                -Pronostics (sur les matchs et les questions)<br>
                -Points du joueur (Classement mis à jour)
                <p class="text-danger font-weight-bold">
                  (CETTE ACTION EST IRRÉVERSIBLE)
                </p>
              </b-card-text>
              <template #modal-footer>
                <b-button
                  variant="secondary"
                  @click="hideModal(`modal-force-delete-user-${data.item.uuid}`)"
                >
                  annuler
                </b-button>
                <b-button
                  variant="danger"
                  :disabled="actionLoading"
                  @click="forceDeleteUser(data.item.uuid)"
                >
                  <b-spinner
                    v-show="actionLoading"
                    small
                  />
                  supprimer
                </b-button>
              </template>
            </b-modal>
          </template>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
            {{ dataMeta.total }} entrées</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="changePage($event)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton, BCardText,
  BCol,
  BForm,
  BFormInput,
  BLink,
  BMedia, BModal, BPagination,
  BRow, BSpinner,
  BTable, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'UsersList',
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BForm,
    vSelect,
    BModal,
    BCardText,
    BTooltip,
  },
  setup() {
    const resolveUserRoleVariant = user => {
      if (user.isSuperAdmin) return 'danger'
      if (user.isAdmin) return 'info'
      if (user.isPlayer) return 'success'
      return 'success'
    }
    const resolveUserRoleIcon = user => {
      if (user.isSuperAdmin) return 'DatabaseIcon'
      if (user.isAdmin) return 'ServerIcon'
      if (user.isPlayer) return 'UserIcon'
      return 'UserIcon'
    }

    const resolveUserRoleName = user => {
      if (user.isSuperAdmin) return 'Super Admin'
      if (user.isAdmin) return 'Admin'
      if (user.isPlayer) return 'Utilisateur'
      return 'Utilisateur'
    }

    const resolveUserBooleanVariant = notification => {
      if (!notification) return 'danger'
      return 'success'
    }
    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserBooleanVariant,
      resolveUserRoleName,
    }
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    platformFilter: {
      type: [String, null],
      default: null,
    },
    trashedFilter: {
      type: [Boolean, null],
      default: null,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    tableColumns: {
      type: [Array, null],
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      actionLoading: false,
      perPageOptions: [5, 25, 50, 100],
      perPage: 25,
      searchQuery: '',
      sortBy: 'created_at',
      isSortDirDesc: true,
      users: [],
      currentPage: 1,
      totalUsers: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        total: 0,
      },
    }
  },
  watch: {
    trashedFilter: {
      handler() {
        this.currentPage = 1
        this.fetchUsers(true)
      },
    },
    platformFilter: {
      handler() {
        this.currentPage = 1
        this.fetchUsers(true)
      },
    },
    roleFilter: {
      handler() {
        this.currentPage = 1
        this.fetchUsers(true)
      },
    },
  },
  mounted() {
    this.fetchUsers()
  },
  methods: {
    async fetchUsers(clearPagination = false) {
      try {
        this.loading = true
        const { data } = await axiosIns.get(this.apiUrl, {
          params: {
            onlyTrashed: this.trashedFilter,
            term: this.searchQuery,
            page: clearPagination ? 1 : this.currentPage,
            perPage: this.perPage,
            sortBy: this.sortBy,
            sortDirection: this.isSortDirDesc ? 'desc' : 'asc',
            role: this.roleFilter,
            platform_id: this.platformFilter,
          },
        })
        this.users = data.data
        this.dataMeta.from = data.meta.from
        this.dataMeta.to = data.meta.to
        this.dataMeta.total = data.meta.total
        this.totalUsers = data.meta.total
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deleteUser(uuid) {
      try {
        this.actionLoading = true
        await axiosIns.delete(`users/${uuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'L\'utilisateur a été supprimé',
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
        this.hideModal(`modal-delete-user-${uuid}`)
        this.fetchUsers()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
      }
    },
    async restoreUser(uuid) {
      try {
        this.actionLoading = true
        await axiosIns.post(`users/${uuid}/restore`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La l\'utilisateur a été restauré',
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
        this.hideModal(`modal-restore-user-${uuid}`)
        this.fetchUsers()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
      }
    },
    async forceDeleteUser(uuid) {
      try {
        this.actionLoading = true
        await axiosIns.delete(`users/${uuid}/forceDelete`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'L\'utilisateur a été définitivement supprimé',
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
        this.hideModal(`modal-force-delete-user-${uuid}`)
        this.fetchUsers()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.actionLoading = false
      }
    },
    sortUsers(e) {
      this.isSortDirDesc = e.sortDesc
      this.sortBy = e.sortBy
      this.fetchUsers()
    },
    changePage(e) {
      this.currentPage = e
      this.fetchUsers()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
