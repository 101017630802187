<template>
  <div>
    <b-card-header class="pb-50">
      <h5>
        Filtres
      </h5>
      <b-button
        type="button"
        variant="primary"
        class="btn-icon"
        :disabled="downloading"
        @click.prevent="usersDataDownload"
      >
        <b-spinner
          v-show="downloading"
          small
        />
        <feather-icon
          v-show="!downloading"
          class="mr-1"
          icon="DownloadIcon"
        />
        Télécharger
      </b-button>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Role</label>
          <v-select
            :dir="'ltr'"
            :value="roleFilter"
            :options="roleOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:roleFilter', val)"
          />
        </b-col>
        <b-col
          v-if="platformOptions"
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Plateforme</label>
          <v-select
            :dir="'ltr'"
            :value="platformFilter"
            :options="platformOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:platformFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Supprimées</label>
          <v-select
            :dir="'ltr'"
            :value="trashedFilter"
            :options="trashedOptions"
            :clearable="false"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:trashedFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardHeader, BCardBody, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { downloadData } from '@/utils'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'UserFilter',
  components: {
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BSpinner,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    platformFilter: {
      type: [String, null],
      default: null,
    },
    trashedFilter: {
      type: [Boolean, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    platformOptions: {
      type: Array,
      default: null,
    },
    trashedOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      downloading: false,
    }
  },
  methods: {
    async usersDataDownload() {
      try {
        this.downloading = true
        if (this.$route.name === 'platforms-view') {
          const result = await axiosIns({ url: `platforms/${this.$route.params.id}/usersExport`, method: 'GET', responseType: 'arraybuffer' })
          downloadData(result, 'users.csv')
        }
        if (this.$route.name === 'users') {
          const result = await axiosIns({ url: 'usersExportAll', method: 'GET', responseType: 'arraybuffer' })
          downloadData(result, 'users.csv')
        }
        this.downloading = false
      } catch (err) {
        this.downloading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
  },
}
</script>

<style scoped>

</style>
