<template>
  <div>
    <b-card no-body>
      <users-list-filters
        :role-filter.sync="userRoleFilter"
        :platform-filter.sync="userPlatformFilter"
        :trashed-filter.sync="userTrashedFilter"
        :role-options="userRoleOptions"
        :platform-options="userPlatformOptions"
        :trashed-options="userTrashedOptions"
      />
      <users-list
        :api-url="apiUrl"
        :role-filter="userRoleFilter"
        :platform-filter="userPlatformFilter"
        :trashed-filter="userTrashedFilter"
        :table-columns="userTableColumns"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UsersListFilters from '@/components/lists/users/UsersFilter.vue'
import UsersList from '@/components/lists/users/UsersList.vue'

export default {
  name: 'Users',
  components: {
    UsersListFilters,
    UsersList,
    BCard,
  },
  data() {
    return {
      loading: true,
      userPlatformFilter: null,
      userRoleFilter: null,
      userPlatformOptions: [],
      userRoleOptions: [
        { label: 'superadmins', value: 'superAdmin' },
        { label: 'admins', value: 'admin' },
        { label: 'joueurs', value: 'player' },
      ],
      userTrashedFilter: false,
      userTrashedOptions: [
        { label: 'Oui', value: true },
        { label: 'Non', value: false },
      ],
      userTableColumns: [
        {
          key: 'name',
          label: 'Utilisateur',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: false,
        },
        {
          key: 'platform',
          label: 'Plateforme',
          sortable: false,
        },
        {
          key: 'role',
          label: 'Role',
          sortable: false,
        },
        {
          key: 'notification',
          label: 'Notification',
          sortable: false,
        },
        {
          key: 'email_verified',
          label: 'Mail vérifié',
          sortable: false,
        },
        {
          key: 'created_at',
          label: 'Date de d\'inscription',
          sortable: true,
        },
        { key: 'actions' },
      ],
      apiUrl: 'users',
    }
  },
  mounted() {
    this.fetchPlatforms()
  },
  methods: {
    async fetchPlatforms() {
      try {
        const { data } = await axiosIns.get('/platforms/minimal')
        data.forEach(el => {
          this.userPlatformOptions.push({
            label: el.name,
            value: el.uuid,
          })
        })
      } catch (err) {
        this.loading = false
        console.log(err)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">

</style>
